import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CompanyGigDto, CompanyGigUserDto } from "model/Gig";
import { Gig } from "./gig.component";
import { PassedOrClosedGig } from "./passedOrClosedGig.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { H3, H4, Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  ButtonWrapper,
  NoGigsWrapper,
  GigsWrapper,
  CheckBoxContainer,
  CheckboxText,
  FilterContainer,
  TextInput,
  ActionContainer,
  SearchContainer,
} from "./gigs.styled";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { Checkbox, Modal } from "@material-ui/core";
import { CreateGigModalBody, ModalBody } from "../calendar/weeklyCalendar.styled";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { Pagination } from "./pagination.component";
import { WorkerWithUrl } from "model/Company";
import { getWorkerImg } from "services/firebase/firebase.service";
import { GigSummaryModal } from "../gigDetail/gigSummaryModal.component";

export const Gigs: React.FC = () => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [isLoading, setIsLoading] = useState(false);
  const [activeGigs, setActiveGigs] = useState<CompanyGigDto[]>([]);
  const [status, setStatus] = useState<"Active" | "Passed" | "Closed">(
    "Active"
  );
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const fetchAmount = 10;
  const [workers, setWorkers] = useState<CompanyGigUserDto[]>([]);
  const [workerWithImageUrl, setWorkersWithImageUrl] =
    useState<WorkerWithUrl[]>();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    fetchGigs();
  }, [status, page, companyState.company]);

  const fetchGigs = () => {
    setActiveGigs([]);

    if (companyState.company?.id) {
      const id = companyState.company.id;
      setIsLoading(true);
      const sortAscending = status === "Active" ? true : false;
      const searchValue = inputValue !== "" ? inputValue : null;

      Api()
        .company.gig.getGigsWithState(
          id,
          status,
          sortAscending,
          page,
          fetchAmount,
          searchValue
        )
        .then((res) => {
          setWorkers(res.data.workers);
          setTotalPages(Math.ceil(res.data.totalGigs / fetchAmount));
          setActiveGigs(res.data.gigs);
        })
        .catch((error) => {
          console.error("Error fetching gigs:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onGigClosed = (gig: CompanyGigDto) => {
    setActiveGigs((old) => old.filter((g) => g.id !== gig.id));
  };

  useEffect(() => {
    if (!workers || workers.length === 0) return;

    const fetchWorkerImages = async () => {
      const updatedWorkers = await Promise.all(
        workers.map(async (worker) => {
          const url = worker.firebaseId
            ? await getWorkerImg(worker.firebaseId.toString())
            : "";
          const newWorker: WorkerWithUrl = {
            workerId: worker.id,
            firebaseId: worker.firebaseId,
            imageUrl: url || "",
          };
          return newWorker;
        })
      );
      setWorkersWithImageUrl(updatedWorkers);
    };

    fetchWorkerImages();
  }, [workers]);

  useEffect(() => {
    setPage(0);
  }, [status]);

  return (
    <>
      <ActionContainer>
        <FilterContainer style={{ margin: 10 }}>
          <CheckBoxContainer>
            <Checkbox
              checked={status === "Active"}
              onChange={() => setStatus("Active")}
            />
            <CheckboxText>{t("GigsCompany.Active")}</CheckboxText>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={status === "Passed"}
              onChange={() => setStatus("Passed")}
            />
            <CheckboxText>{t("GigsCompany.Passed")}</CheckboxText>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={status === "Closed"}
              onChange={() => setStatus("Closed")}
            />
            <CheckboxText>{t("GigsCompany.Archived")}</CheckboxText>
          </CheckBoxContainer>
        </FilterContainer>
        <SearchContainer>
          <TextInput
            value={inputValue}
            onChange={(e: any) => {
              setInputValue(e.currentTarget.value);
            }}
          />
          <CustomIcon
            name="search"
            color={Color.LighterPositive}
            size="28px"
            style={{ margin: 6 }}
            onClick={() => {
              fetchGigs();
            }}
          />
        </SearchContainer>
      </ActionContainer>
      <GigsWrapper loading={isLoading}>
        {activeGigs.map((gig) => {
          return status === "Active" ? (
            <Gig
              key={gig.id}
              gigData={gig}
              onGigClosed={onGigClosed}
              workerWithImageUrl={workerWithImageUrl}
            />
          ) : (
            <PassedOrClosedGig
              key={gig.id}
              gigData={gig}
              workerWithImageUrl={workerWithImageUrl}
            />
          );
        })}
        {!activeGigs.length && !isLoading && (
          <NoGigsWrapper>
            <CustomIcon name="document" size="130px" color={Color.SeaBlue400} />
            <H4>{t("GigsCompany.NoGigs")}</H4>
            <Text color={Color.SeaBlue400} padding>
              {t("GigsCompany.NoPublishedGigs")}
            </Text>
          </NoGigsWrapper>
        )}
      </GigsWrapper>
      {isLoading && <LoaderProgress />}
      {activeGigs.length !== 0 && (
        <Pagination setPage={setPage} page={page} totalPages={totalPages} />
      )}
    </>
  );
};
